import React from "react"
import AwardStyledBackgroundSection from "../components/award-background-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import Link from "gatsby-link"
import { graphql } from "gatsby"

const Awards = ({data}) => (
  <Layout>
    <Seo title="Awards" />
    <AwardStyledBackgroundSection />
    <div className="award-highlight-one">
      <p className="h3">2024 Professional Award Judges</p>
    </div>
    <div className="image-row">
      <div className="image-wrapper">
        <Img
          fluid={data.barbara.childImageSharp.fluid}
          alt="Barbara Allen"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox">
          <div className="image-title">Barbara Allen</div>
          <div className="caption">Director of College Programming, Poynter</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.kathleen.childImageSharp.fluid}
          alt="Kathleen McElroy"
          style={{width: `100%`,}}
        />
        <div className="image-row-textbox">
          <div className="image-title">Kathleen McElroy, Ph.D.</div>
          <div className="caption">Director, School of Journalism and Media, University of Texas at Austin</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.earnest.childImageSharp.fluid}
          alt="Earnest Missou"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox">
          <div className="image-title">Earnest Perry, Ph.D.</div>
          <div className="caption">Associate Dean for Graduate Studies, University of Missouri School of Journalism</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.james.childImageSharp.fluid}
          alt="James Rada"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox">
          <div className="image-title">James Rada</div>
          <div className="caption">Documentary Producer and Professor, Virginia State</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.andrewr.childImageSharp.fluid}
          alt="Andrew Rosenthal"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox">
          <div className="image-title">Andrew Rosenthal</div>
          <div className="caption">Former editorial page editor and national editor at The New York Times.</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.johnbridges.childImageSharp.fluid}
          alt="John Bridges"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox">
          <div className="image-title">John Bridges</div>
          <div className="caption">Former editor of the Austin American-Statesman and journalism lecturer at University of Texas at Austin.</div>
        </div>
      </div>
    </div>
    <div className="award-highlight-two">
      <p className="h3">2024 Student Media Award Judges</p>
    </div>
    <div className="image-row">
      <div className="image-wrapper">
        <Img
          fluid={data.jeff.childImageSharp.fluid}
          alt="Jeff Cohen"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox two">
          <div className="image-title">Jeff Cohen</div>
          <div className="caption">Executive Vice President, Arnold Ventures<br />Former Hearst editor</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.sonni.childImageSharp.fluid}
          alt="Sonni Efron"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox two">
          <div className="image-title">Sonni Efron</div>
          <div className="caption">President and COO, National Press Foundation</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.rosa.childImageSharp.fluid}
          alt="Rosa Flores"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox two">
          <div className="image-title">Rosa Flores</div>
          <div className="caption">CNN Correspondent</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.courtney.childImageSharp.fluid}
          alt="Courtney Norris"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox two">
          <div className="image-title">Courtney Norris</div>
          <div className="caption">Producer, “PBS NewsHour”</div>
        </div>
      </div>
      <div className="image-wrapper">
        <Img
          fluid={data.plohetski.childImageSharp.fluid}
          alt="Tony Plohetski"
          style={{ width: `100%`, }}
        />
        <div className="image-row-textbox two">
          <div className="image-title">Tony Plohetski</div>
          <div className="caption">Austin American-Statesman/KVUE-TV<br />Inaugural Dan Rather Medal Winner</div>
        </div>
      </div>
    </div>
    <div className="event-details">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="h3 mb-5 serif">Medals Timeline</h2>
            <p className="text-left copy mb-5">Rather, the School of Journalism and Media, and the Moody College of Communication believe now is an especially appropriate time to highlight the work of journalists facing threats for trying to fulfill their vital role in American democracy. The medals are designed to complement rather than compete against other journalism awards.</p>
            {/* <p className="italic mb-2 semibold">Introducing Medals Announcement</p>
            <p className="bold h4 mb-4 semibold">Dec. 16</p> */}
            <p className="italic mb-2 semibold">Nominations Accepted</p>
            <p className="bold h4 mb-4 semibold">January 1 - January 31</p>
            <p className="italic mb-2 semibold">Judging</p>
            <p className="bold h4 mb-4 semibold">February 1 – February 28</p>
            <p className="italic mb-2 semibold">Winners and Finalists Announced</p>
            <p className="bold h4 mb-4 semibold">March 30</p>
            {/* <p className="italic mb-2 semibold">Winners Announced</p>
            <p className="bold h4 mb-4 semibold">
              <Link to="https://journalism.utexas.edu/news/winners-announced-dan-rather-medals" style={{ color: `inherit`, textDecoration: `underline`, textAlign: `center` }}>
                March 24
              </Link>
            </p> */}
            {/* <p className="italic mb-2 semibold">Winning Announcement</p>
            <p className="bold h4 mb-4 semibold">Wednesday, March 24</p> */}
            {/* <p className="italic mb-2 semibold">Celebration</p>
            <p className="bold h4 semibold">June</p> */}
          </div>
        </div>
      </div>
    </div>
    <div className="briscoe-credit mt-2 mb-2">
      <div>
        <p className="small-copy">Dan Rather on CBS news assignment.</p>
        <p className="small-copy">Robert McNeely Photographic Archive,</p>
        <p className="small-copy">Briscoe Center for American History.</p>
      </div>
    </div>
  </Layout>
)

export default Awards

export const query = graphql`
  query {
    kathleen: file(relativePath: { eq: "2020_photo_KOMcElroy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earnest: file(relativePath: { eq: "earnest-perry-missou.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    avatar: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    barbara: file(relativePath: { eq: "barbara-allen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    manny: file(relativePath: { eq: "Manny_Garcia.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sonni: file(relativePath: { eq: "sonni-efron.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    james: file(relativePath: { eq: "rada.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    courtney: file(relativePath: { eq: "courtney-norris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jeff: file(relativePath: { eq: "JeffCohen-Headshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rosa: file(relativePath: { eq: "RosaFlores_Headshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    plohetski: file(relativePath: { eq: "Plohetski.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    andrewr: file(relativePath: { eq: "Andrew_Rosenthal.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    johnbridges: file(relativePath: { eq: "JohnBridges.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
