import React from "react"
import BackgroundVideo from "../components/video-background-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import Montage from "../components/montage"
import Link from "gatsby-link"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <BackgroundVideo />
    <div id="home-quote">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p className="quote serif">
              Do a good job and report the truth, and you're probably going to
              catch hell. The challenge is being willing to do it anyway.
            </p>
            <p className="credit">— Dan Rather</p>
          </div>
        </div>
      </div>
    </div>
    <Montage />
    <div id="home-our-award">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="h3 serif">Our Awards</h2>
            <Img
              fluid={data.medal.childImageSharp.fluid}
              alt="Image of the Dan Rather Medal"
              style={{ width: `100%`, maxWidth: `300px` }}
              className="medal-image"
            />
            <p className="mt-5">
              The Dan Rather Medals for News and Guts honor the process of
              journalism as much as the end product. They will be awarded to
              professional and collegiate journalists who go the extra yard —
              overcoming obstacles like stonewalling and harassment — to get the
              story that tells truth to power.
            </p>
            <p>
              The Medals are named for Dan Rather, the legendary reporter and
              anchor who went far afield from his Texan roots but never forgot
              his humble beginnings. He has been an ardent supporter of the
              School of Journalism and Media, inspiring and advocating for our
              majors. He is a permanent member of the Moody College of
              Communication’s Advisory Council, where he has been instrumental
              in its extraordinary development. The School and the College
              sponsor the Dan Rather Medals for News and Guts to further his
              goal of supporting and defending bravery and excellence in
              journalism in the face of overwhelming odds. While journalism and
              journalists have long been under constant fire from the powerful,
              recognizing those who show “News and Guts” has never been more
              important than it is today.
            </p>
            <p className="mb-5">
              As Rather once said, "News organizations and teams within those
              organizations have to have the guts and the backbone to dig into
              stories that people in power don’t want the public to know.” These
              medals in his name, to be presented to professional and college
              journalists, honor that effort.
            </p>
            <a
              target="_blank"
              href="https://danrathermedals.com/winners/"
              style={{
                color: `inherit`,
                textDecoration: `underline`,
                textAlign: `center`,
              }}
            >
              <p>Winners announced for the 2024 awards.</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Link to="/apply" activeStyle={{ opacity: ".5" }}>
      <div id="home-achievement">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12"
              style={{
                maxWidth: `880px`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
            >
              <h2 className="h3 serif">
                Dan Rather Medals for Excellence in News and Guts
              </h2>
              <hr />
              <div className="row">
                <div className="col-sm-12 col-md-6 pt-4 pb-4 borderright">
                  <h3 className="h2 semibold">Professional</h3>
                  <p className="h3 serif">$5,000 Award</p>
                </div>
                <div className="col-sm-12 col-md-6 pt-4 pb-4">
                  <h3 className="h2 semibold">Student Media</h3>
                  <p className="h3 serif">$5,000 Award</p>
                </div>
              </div>
              <hr />
              {/* <a href="https://www.google.com" className="small-link">Learn More</a> */}
            </div>
          </div>
        </div>
        <div className="briscoe-credit">
          <div>
            <p className="small-copy text-left mb-3">
              Selected images from the photographic archives of Dirck Halstead,
              Matthew Naythons, Robert “Bob” McNeely, Dennis Brack and the
              Philip Scheffler Papers, Marion Goldin Papers and Rather (Dan)
              Papers at the Briscoe Center for American History.
            </p>
            <p className="small-copy text-left">
              Photos of Dan Rather and Richard Nixon and Dan Rather in
              Afghanistan from Getty Images.
            </p>
          </div>
        </div>
      </div>
    </Link>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    medal: file(relativePath: { eq: "new-medal.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
