import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "dan-smiling.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <div>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            id="hero-section"
            style={{ marginTop: `130px` }}
          ></BackgroundImage>
          <div className="hero-textbox pad">
            <div id="award-hero-textbox">
              <div className="container">
                <div className="row">
                  <div
                    className="col-sm-12 mb-5"
                    style={{
                      maxWidth: `880px`,
                      marginLeft: `auto`,
                      marginRight: `auto`,
                    }}
                  >
                    <h1 className="mb-5 semibold serif">
                      Nominate for an Award
                    </h1>
                    <h2 className="h3 mb-3 serif">Eligibility</h2>
                    <p className="mb-5 small-copy">
                      The journalistic content—text, audio, broadcast, video or
                      graphics—must be published or aired to the public.
                    </p>
                    <ul className="award-list small-copy text-left">
                      <li>
                        Open to United States news media in the form of print,
                        broadcast, radio or digital. Freelance journalists are
                        eligible.
                      </li>
                      <li>
                        Published or broadcast between Jan. 1, and Dec. 31, 2024. A
                        series is eligible, but all content must be completed
                        within the eligibility date range.
                      </li>

                      <li>
                        Collaborations are eligible for entry and count as one
                        submission.
                      </li>
                      <li>
                        Journalists, a team of journalists, organizations or
                        collaborations can be nominated for up to two entries.
                      </li>
                    </ul>
                    <hr />
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="h3 mb-4 serif">How to Submit</h2>
                        <p className="award-list small-copy text-left">
                          Applicants for the Dan Rather Medals submit their work
                          directly online via the submission portal, beginning
                          Jan. 1, 2025, through Jan. 31, 2025. Team entries must
                          indicate a project team lead for contact.
                        </p>
                        <p class="small-copy">Any questions?</p>
                        <p class="small-copy">
                          <a
                            class="button m-3"
                            target="_blank"
                            href="mailto:johnbridges@utexas.edu?subject=Dan Rather inquiry"
                          >
                            Email us
                          </a>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <h2 className="h3 mt-4 pt-4 border-top border-3 mb-4 serif">
                          What to Submit
                        </h2>
                        <ul className="award-list small-copy text-left">
                          <li>
                            A single news story or up to three dated pieces in a
                            series.
                          </li>
                          <li>
                            If the entry is a series, you may upload up to three
                            installments/episodes for review by the judges, in
                            chronological order.
                          </li>
                          <li>
                            A brief statement (up to 600 words) describing how
                            the content meets the selection criteria of
                            overcoming extraordinary conditions in reporting.
                          </li>
                          <li>
                            All materials are submitted online through the Dan
                            Rather Medals submission form. If materials are not
                            available via URL and materials (in pdf, mp3., mp4.,
                            or mov. format) need to be uploaded, please
                            carefully read the upload instructions below.
                          </li>
                        </ul>
                      </div>

                      <div className="col-sm-12">
                        <h2 className="h3 mt-4 pt-4 border-top border-3 mb-4 serif">
                          Upload Instructions
                        </h2>
                        <div className="award-list small-copy text-left">
                          <p>
                            Please read the upload instructions carefully. All
                            materials for one nomination should be uploaded as
                            ONE zip folder. The total size of your folder before
                            you compress it must not exceed 100MB.
                          </p>
                          <div>
                            <span>
                              <strong>Step 1.</strong> Name your files
                            </span>
                            <ul>
                              <li>
                                Each story should be named using the{" "}
                                <strong>nominee</strong> name in the following
                                manner:{" "}
                                <i>
                                  Jane Doe story_1.pdf, Jane Doe story_2.pdf
                                  etc.{" "}
                                </i>
                              </li>
                              <li>
                                Each video/podcast should be name using the{" "}
                                <strong>nominee</strong> name followed by
                                Video_1, Video_2:
                                <i>Jane Doe video_1, Jane Doe video_2 etc.</i>
                              </li>
                              <li>
                                Each audio/podcast should be name using the{" "}
                                <strong>nominee</strong> name followed by
                                Audio_1, Audio_2:
                                <i>Jane Doe audio_1, Jane Doe audio_2 etc.</i>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="award-list small-copy text-left">
                          <div>
                            <span>
                              <strong>Step 2.</strong> Once you have the
                              materials ready for upload, place them in a folder
                              with the nominee name. Then compress this folder
                              into a zip file and you are ready to upload using
                              the box upload button.
                            </span>
                          </div>
                        </div>
                        <div className="award-list small-copy text-left pt-3">
                          <div>
                            <span>
                              <strong>Step 3.</strong> Upload your zip file.
                            </span>
                          </div>
                          <div>
                            <strong class="underline">PLEASE NOTE:</strong> Box
                            will not accept your folder unless it is compressed
                            into a <strong class="underline">zip file</strong>.
                            Remember that the total size of your folder before
                            you compress it must not exceed 100MB.
                            <div class="col-md-12 mt-5 pb-4 text-align-center">
                              <a
                                class="button"
                                href="https://forms.office.com/Pages/ResponsePage.aspx?id=peLXMdi9TkGel76pmOvf4Zcn5G7r8r5CkT6j-PY4YStUNVFRVjNTOUhZSko1RzhBMlBZVDVCU1FINS4u"
                              >
                                Nomination Form
                              </a>
                            </div>
                            <div class="col-md-12 pt-4 mb-4 text-align-center ">
                              <a
                                class="button"
                                href="https://utexas.app.box.com/f/4e338ceec463416497c63d7ea02c4fa8"
                              >
                                Upload materials
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="p-3">
                          <p class="small-copy">
                            If you are having issues uploading? Please contact{" "}
                            <a href="mailto:liesbeth@austin.utexas.edu">
                              Liesbeth Demaer
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-4" />
                    {/* <div className="button-wrapper mt-5">
                      <p>
                        The application for 2023 is closed. Please check back in
                        Fall 2023 for the 2024 application.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

const ApplyStyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default ApplyStyledBackgroundSection
